<template>
  <div>
    <filter-slot
      :filter="Filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      @reload="$refs['ManageCourtsList'].refresh()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button variant="primary" @click="openModalCourtManagment()">
            <feather-icon icon="PlusIcon" size="15" class="mr-50" />NEW COURT
          </b-button>
        </div>
      </template>
      <b-table
        ref="ManageCourtsList"
        slot="table"
        class="blue-scrollbar"
        primary-key="id"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        small
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(website)="row">
          <div class="d-flex">
            <change-sms
              :sms-data="row.value"
              :TextLength="50"
              class="text-primary"
            ></change-sms>
            <a v-if="row.value" :href="row.value" target="_blank">
              <feather-icon icon="ExternalLinkIcon" size="15" class="ml-1" />
            </a>
          </div>
        </template>
        <template v-slot:cell(ci_name)="data">
          <change-sms
            :sms-data="data.item.ci_name"
            :TextLength="35"
          ></change-sms>
        </template>
        <template #cell(address)="data">
          <change-sms
            :sms-data="data.item.address"
            :TextLength="50"
          ></change-sms>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            v-b-tooltip.hover.left="'Edit court'"
            icon="EditIcon"
            size="18"
            class="cursor-pointer text-warning mr-1"
            @click="openModalEditCourtManagment(data.item)"
          />
          <feather-icon
            v-b-tooltip.hover.left="
              data.item.state === 1 ? 'Delete court' : 'Activate court'
            "
            :icon="data.item.state === 1 ? 'TrashIcon' : 'CheckCircleIcon'"
            size="18"
            :class="
              data.item.state === 1
                ? 'cursor-pointer text-danger'
                : 'cursor-pointer text-success'
            "
            @click="deleteCourtManagment(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
    <modal-court-management
      v-if="showModal"
      :courtId="courtId"
      @close="showModal = false"
      @closing="$refs['ManageCourtsList'].refresh()"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ModalCourtManagement from "@/views/debt-solution/views/settings/views/manageCI/components/modal/ModalCourtManagement.vue";
import ManageCourtInfoFields from "@/views/debt-solution/views/settings/views/manageCI/data/manage-court-info.fields.js";
import SettingsServices from "@/views/debt-solution/views/settings/views/manageCI/services/Settings.service.js";
import Filters from "@/views/debt-solution/views/settings/views/manageCI/data/filters.data.js";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
export default {
  components: { ModalCourtManagement, ChangeSms },
  data() {
    return {
      showModal: false,
      courtId: null,
      fields: ManageCourtInfoFields,
      Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Court Name...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_STATES: "StandarStore/G_STATES",
    }),
  },
  async mounted() {
    await this.A_GET_STATES();
    this.Filters[2].options = this.G_STATES;
  },
  methods: {
    ...mapActions({
      A_GET_STATES: "StandarStore/A_GET_STATES",
    }),

    async myProvider(ctx) {
      this.addPreloader();

      let order = ctx.sortDesc ? "asc" : "desc";
      let order_by = ctx.sortBy ? ctx.sortBy : "id";
      order = ctx.sortBy ? order : "desc";

      try {
        const { data } = await SettingsServices.getSettingsManageCI({
          perPage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          ci_name: this.filterPrincipal.model,
          order: order,
          order_by: order_by,
          state: this.Filters[3].model,
          date_from: this.Filters[0].model,
          date_to: this.Filters[1].model,
          states_eeuu: this.Filters[2].model,
        });

        this.totalRows = data.total;
        this.paginate.startPage = data.from ? data.from : 0;
        this.paginate.toPage = data.to ? data.to : 0;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openModalCourtManagment() {
      this.showModal = true;
      this.courtId = null;
    },

    openModalEditCourtManagment(item) {
      this.showModal = true;
      this.courtId = item.id;
    },

    async deleteCourtManagment(item) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const { data } = await SettingsServices.deleteCourtManagement({
            id: item.id,
          });
          if (item.state === 1) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              `Court deleted successfully`
            );
          } else {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              `Court activated successfully`
            );
          }
          this.$refs["ManageCourtsList"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
  
<style>
</style>