// FIELDS OF TABLEBANKACCOUNT
export default[
    {
        key: "ci_name",
        label: "Court Name",
        sortable: true,
    },
    {
        key: "county",
        label: "County",
        sortable: true,
    },
    {
        key: "address",
        label: "Address",
        sortable: false,
    },
    {
        key: "state_eeuu",
        label: "State",
        sortable: true,
    },
    {
        key: "city",
        label: "City",
        sortable: true,
    },
    {
        key: "number",
        label: "Phone",
        sortable: false,
    },
    // {
    //     key: "website",
    //     label: "Website",
    //     sortable: false,
    // },
    // {
    //     key: "case_search",
    //     label: "Case Search",
    //     sortable: false,
    // },
    // {
    //     key: "document_access",
    //     label: "Document Access",
    //     sortable: false,
    // },
    {
        key: "user",
        label: "User",
        sortable: false,
    },
    {
        key: "created_at",
        label: "Createt At",
        sortable: false,
    },
    {
        key: "actions",
        thClass: "text-center",
        tdClass: "text-center",
    },

]